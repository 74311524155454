'use client'

import { FC, useEffect } from 'react'
import Error from 'next/error'
import * as Sentry from '@sentry/nextjs'

type Props = {
  error: Error
}

const GlobalError: FC<Props> = ({ error }) => {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])
  return (
    <html>
      <body>
        <Error
          statusCode={error?.props?.statusCode}
          title={error?.props?.title}
        />
      </body>
    </html>
  )
}

export default GlobalError
